/*header*/
#header{
  border-bottom: 3px solid #379D89;
  border-top: 1px solid var(--layout_border_color);
}
.dark #header{
  background: #112;
}

#header .header_con{
  @apply relative;
  min-width:var(--min_width);
  max-width:var(--max_width);
  margin:0 auto;
}
#header .header_top_wrap{
  position: relative;
  height: 45px;
}
#header .logo_area{
  width: 250px;
  margin-left:20px;
}
#header .logo_area img{
  max-height:45px;
}
#header .header_top_link_wrap{
  position:absolute;
  right: 10px;
  top:12px;
  display: inline-block;
  line-height: 20px;
  z-index: 1;
}
#header .header_top_link_btn{
  font-size: 13px;
  color:#000;
  cursor: pointer;
}
.dark #header .header_top_link_btn{
  color:#ffffff;
}
#header .header_top_link_btn:hover{
  color:#1db070;
}
#header .header_top_split{
  font-size: 11px;
  color:#bbb;
  margin:0 5px;
}

#header .gnb_wrap{
  @apply flex flex-row relative;
}
#header .gnb_area{
  @apply flex flex-row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap:30px;
  margin-left: 10px;
}
#header .site_map_btn{
  width:40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #889242;
  background-color: #fff;
  margin-left:10px;
}

@media screen and (max-width:700px) {
  #header .logo_area{
    width: 100px;
    margin-left: 0px;
    position: absolute;
    top:5px;
    left:10px;
    display: inline-block;
    line-height: 25px;
    z-index: 10;
  }
  #header .logo_area img{
    max-height:30px;
  }
  #header .header_top_link_wrap{
    position: relative;
    right: 0;
    top: 0;
    display: block;
    line-height: 40px;
    text-align: right;
    padding-right: 10px;
  }
}
@media screen and (max-width:600px) {
  #header .gnb_area{
    justify-content: center;
    flex-wrap: wrap;
    line-height: 20px;
    gap:5px;
    margin-left: 0px;
  }
  #header{
    height: auto;
  }
}