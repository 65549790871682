*{
  font-size: 15px;
}
body{
  font-family: sans-serif;
  font-weight: 400;
}

body img,body video{
  display: inline-block;
}

body h1,h2,h3,h4,h5{
  /* white-space:nowrap; */
  font-weight: 500;
}
body h1{
  @apply text-3xl;
}
body h2{
  @apply text-2xl;
}
body h3{
  @apply text-xl;
}
body h4{
  @apply text-lg;
}
body h5{
  @apply text-base;
}

body a{
  cursor: pointer;
}

body input{
  border:1px solid #ddd;
  color:#0b0000;
  accent-color: #10AC40;
  font-weight: 400;
}
body input[type=radio]{
  accent-color: #10AC40;
}
body select{
  font-weight: 400;
}
.dark body input{
  background: #636363;
  color:#dcdcdc;
}
.dark select{
  background: #636363;
  color:#dcdcdc;
}