.box_wrap{
  margin-top:10px;
  padding:10px;
  border:1px solid #e5e5e5;
}
.img_box_wrap{
  width: 100%;
  overflow: auto;
  margin-top:5px;
}
.img_box_img{
  width: 100%;
}