.write-table-div{
  @apply w-full relative;
}
.write-table-div table{
  @apply w-full text-left text-gray-900 dark:text-gray-400 border-collapse;
}
.write-table-div th{
  @apply bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-200;
  border:1px solid #C4C4C4;
  padding:10px;
  min-width: 60px;
  font-size: 14px;
  text-align: center;
}
.write-table-div td{
  @apply dark:bg-gray-800 dark:text-gray-400;
  border:1px solid #C4C4C4;
  padding:10px;
  min-width: 100px;
  font-size: 14px;
  font-weight: 400;
}

.write-table-div .row-input{
  @apply bg-gray-50 border border-gray-300 text-gray-900
  focus:ring-blue-500 
  focus:border-blue-500 
  w-full
  dark:bg-gray-700 
  dark:border-gray-600 
  dark:placeholder-gray-400 
  dark:text-white 
  dark:focus:ring-blue-500 
  dark:focus:border-blue-500;
  padding:8px;
  font-size: 14px;
  font-weight: 400;
}

.emphasis_star{
  color:#C43C00;
  margin-left: 3px;
  font-size: 12px;
  font-weight: bold;
}

.view-table-div{
  @apply w-full relative sm:rounded-lg;
}
.view-table-div table{
  @apply w-full text-left border-collapse;
}
.view-table-div tr{
  
}
.view-table-div th, .view-table-div td{
  @apply py-1 px-2 text-base border border-gray-300 dark:border-slate-700;
}
.view-table-div th{
  @apply bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200 text-center;
  min-width: 100px;
}
.view-table-div td{
  @apply bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-400;
  min-width: 120px;
}

.write_table_small{
  width: 100%;
}
.write_table_small table{
  width: 100%;
  border-collapse: collapse;
}
.write_table_small th, .write_table_small td{
  border:1px solid #d8d8d8;
  padding:2px 2px;
  min-width: 35px;
  font-size: 13px;
}
.write_table_small th{
  @apply bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-200;
  text-align: center;
}
.write_table_small td{
  @apply dark:bg-gray-800 dark:text-gray-400;
  font-weight: 400;
}

.write_table_small .row-input{
  @apply bg-gray-50 border border-gray-300 text-gray-900
  focus:ring-blue-500 
  focus:border-blue-500 
  w-full
  dark:bg-gray-700 
  dark:border-gray-600 
  dark:placeholder-gray-400 
  dark:text-white 
  dark:focus:ring-blue-500 
  dark:focus:border-blue-500;
  padding:3px 3px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
}

.write_select_tab{
  display: flex;
  flex-wrap: wrap;
  gap:5px;
}
.write_select_tab_item{
  flex-grow: 1;
  text-align: center;
  line-height: 25px;
  padding:0px 5px;
  background: #a7a7a7;
  color:#fff;
  font-size: 14px;
  cursor: pointer;
}
.write_select_tab_item:hover{
  background: rgb(0, 222, 207);
}
.write_select_tab_item.active{
  background: #00968b;
}

.select_box_bar{
  display: flex;
  flex-wrap: wrap;
  gap:5px;
  justify-content: center;
}
.select_box_bar_item{
  min-width: 25px;
  height: 25px;
  line-height: 25px;
  padding:0px 3px;
  text-align: center;
  background: #a7a7a7;
  color:#fff;
  font-size: 14px;
  cursor: pointer;
}
.select_box_bar_item:hover{
  background: rgb(0, 222, 207);
}
.select_box_bar_item.active{
  background: #00968b;
}

.select_box_bar_small{
  display: flex;
  flex-wrap: wrap;
  gap:2px;
  justify-content: center;
}
.select_box_bar_item_small{
  min-width: 16px;
  height: 20px;
  line-height: 20px;
  padding:0px 2px;
  text-align: center;
  background: #a7a7a7;
  color:#fff;
  font-size: 12px;
  cursor: pointer;
}
.select_box_bar_item_small:hover{
  background:#69ff52;
}
.select_box_bar_item_small.active{
  background: #00968b;
}

@media screen and (max-width:700px) {
  .write-table-div th{
    padding:5px;
    min-width: 70px;
    font-size: 13px;
  }
  .write-table-div td{
    padding:5px;
    min-width: 60px;
    font-size: 13px;
  }
}