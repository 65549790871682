.btn-s {
  @apply rounded focus:ring-1;
  white-space:nowrap;
  padding: 0px 4px;
  font-size:12px;
  line-height: 18px;
  cursor: pointer;
}
.btn-s2 {
  @apply rounded focus:ring-1;
  white-space:nowrap;
  padding: 3px 4px;
  font-size:12px;
  line-height: 12px;
  cursor: pointer;
}
.btn {
  @apply font-bold rounded;
  white-space:nowrap;
  padding: 4px 7px;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}
.btn-m{
  white-space:nowrap;
  padding:0px 10px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}
.btn-m2{
  white-space:nowrap;
  padding:4px 5px;
  font-size: 14px;
  line-height: 15px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
}
.btn-m3{
  white-space:nowrap;
  padding:0px 5px;
  font-size: 14px;
  line-height: 21px;
  height: 30px;
  font-weight: 500;
  cursor: pointer;
}
.btn-l {
  @apply font-bold px-7 py-3 rounded text-base;
  white-space:nowrap;
  font-weight: 500;
  cursor: pointer;
}
.btn-l2 {
  @apply rounded;
  font-size: 16px;
  white-space:nowrap;
  font-weight: 500;
  padding: 0px 20px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.btn-xl {
  @apply font-bold px-7 py-4 rounded text-xl;
  font-weight: 500;
  white-space:nowrap;
  cursor: pointer;
}

.btn-box-center{
  @apply flex flex-wrap space-x-1 gap-0.5 justify-center content-center
}
.btn-box-center  > *{
  align-self: center;
}
.btn-box-left{
  @apply flex flex-wrap space-x-1 gap-0.5 justify-start content-center
}
.btn-box-left  > *{
  align-self: center;
}
.btn-box-right{
  @apply flex flex-wrap space-x-1 gap-0.5 justify-end content-center
}
.btn-box-right  > *{
  align-self: center;
}

.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}

.btn-sky{
  background: #0097BA;
  color: #fff;
}

.btn-yellow {
  @apply text-white bg-yellow-400;
}
.btn-yellow:hover {
  @apply bg-yellow-500;
}

.btn-red {
  @apply text-white;
  background: #E22F04 ;
}
.btn-red:hover {
  background: #a02000 ;
}

.btn-dark {
  @apply text-white bg-btndark;
}
.btn-dark:hover {
  background-color: #6a6a68;
}

.btn-black {
  @apply text-white bg-black;
}
.btn-black:hover {
  background-color: #333333;
}

.btn-gray {
  @apply text-white bg-btngray;
}
.btn-gray:hover {
  background-color:#b3b3b3;
}

.btn-brown {
  @apply text-white;
  background:#8e8d07;
}
.btn-brown:hover {
  background:#6d6d04;
}

.btn-yellowish {
  @apply text-white;
  background:#AEBE00;
}
.btn-yellowish:hover {
  background:#8c9900;
}

.btn-yellowish2 {
  @apply text-white;
  background:#3FAC96;
}
.btn-yellowish2:hover {
  background:#338c7a;
}

.btn-yellowish3 {
  @apply text-white;
  background:#0197BA;
}
.btn-yellowish3:hover {
  background:#007690;
}

.btn-darkgreen {
  @apply text-white;
  background:#889242;
}
.btn-darkgreen:hover {
  background:#727b37;
}


.btn-line-gray{
  @apply dark:text-gray-200;
  border:1px solid #707070;
  color:#505050;
}
.btn-line-gray:hover{
  @apply text-gray-500 dark:text-gray-100;
  border:1px solid #989898;
  color:#989898;
}
.btn-line-yellow-green{
  border:1px solid #aab80f;
  color:#aab80f;
}
.btn-line-yellow-green:hover{
  border:1px solid #cbdb16;
  color:#cbdb16;
}
.btn-line-yellowish2{
  border:1px solid #3FAC96;
  color:#3FAC96;
}
.btn-line-yellowish2:hover{
  border:1px solid #338c7a;
  color:#338c7a;
}

.btn-round-yellow {
  @apply text-white bg-yellow-400 rounded-full;
}
.btn-round-yellow:hover {
  @apply bg-yellow-500;
}

.btn-round-green {
  @apply text-white bg-green-600 rounded-full;
}
.btn-round-green:hover {
  @apply bg-green-700;
}

.btn-round-green2 {
  @apply text-white bg-green-500 rounded-full;
}
.btn-round-green2:hover {
  @apply bg-green-600;
}

.btn-round-dark {
  @apply text-white rounded-full;
  background-color: #5e5e5e;
}
.btn-round-dark:hover {
  background-color: #4b4b4b;
}

.btn-round-orange {
  @apply text-white bg-orange-500 rounded-full;
}
.btn-round-orange:hover {
  @apply bg-orange-600;
}