.select_folder_span{
  display: inline-block;
  min-width: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  color:#000000;
  background: #ffffff;
  border-radius: 5px;
  border:1px solid #ccc;
  padding: 0 10px;
  margin-left: 10px;
  cursor: pointer;
}

.box_wrap{
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  gap:10px;
}
.box{
  display: inline-block;
  position: relative;
  width: 150px;
  height: 195px;
}
.box_con{
  width: 100%;
  height: 150px;
  line-height: 150px;
  border:1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.box_con_img{
  max-width: 100%;
  max-height: 100%;
  width: 80px;
}
.box_title_wrap{
  width: 100%;
  height: 25px;
  line-height: 25px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box_file_size_wrap{
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  color:#888;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box_check_input{
  position: absolute;
  top: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 10;
}