.login_wrap{
  padding-top:220px;
}
.login_box{
  width: 410px;
  max-width: 100%;
  min-width: 200px;
  margin:0 auto;
  border-radius: 5px;
  padding: 20px 28px;
}
.title_logo_wrap{
  text-align: center;
}
.title_logo{
  width: 130px;
}

.login_comp_input,.login_id_input,.login_pw_input{
  @apply text-base p-2;
  padding-left: 20px;
  border:1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}
.login_comp_input:focus,.login_id_input:focus,.login_pw_input:focus{
  outline: none !important; border-color: #b3b3b3; box-shadow: 0 0 8px #a8e9ba;
}
input::placeholder {
  color: #bbb;
  font-style: italic;
}
.after_fix_input{
  position: absolute;
  right:10px;
  top:7px;
  font-size:15px;
  color:#bbb;
  z-index: 1;
}

.check_login_label{
  font-size: 12px;
  color:#707070;
}
.check_login_label input[type="checkbox"]{
  margin-right: 5px;
  width: 12px;
  height: 12px;
}

.login_btn{
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  color:#fff;
  background: #3FAC96;
  width: 100%;
}
.login_btn:hover {
  background:#25705c;
}